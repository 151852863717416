import React, {Component} from 'react'
import classnames from 'classnames'
import {getPresentoonById} from './externalApiBridge'
import userTrackingService from 'common/services/userTrackingService'
import {
  getPublishData,
  getIsLicenseUpgradeRequired,
  getIsLimitUpgradeRequired,
  getUserLicense
} from './PublishTargetsService'
import './UpgradeTooltipWrapper.scss'

export default class UpgradeTooltipWrapper extends Component {
  constructor(props) {
    super(props)

    const {contentcheck, licensecheck} = this.props

    this.state = {
      upgradeMessage: '',
      upgradeUrl: '',
      tooltipActivated: '',
      contentcheck,
      licensecheck
    }
  }

  componentDidMount = () => {
    window.addEventListener('click', this.pageClick, false)
  }

  getUpgradeStatusOrigin = (target, isBlockedByPremiumAssets) => {
    const upgradeUrl = '/pricing/'
    let upgradeButton = 'Upgrade Now'
    let upgradeMessage = isBlockedByPremiumAssets
      ? 'Your Powtoon contains premium objects. \
        To export or share the Powtoon, please upgrade your \
        subscription plan or remove the objects.'
      : 'To use this feature, upgrade your account'

    const isLimitUpgradeRequired = getIsLimitUpgradeRequired(target)
    const isLicenseUpgradeRequired = getIsLicenseUpgradeRequired(target)
    if (isLicenseUpgradeRequired) {
      this.setState({
        tooltipActivated: true,
        upgradeButton,
        upgradeUrl,
        upgradeMessage,
        isLimitedExport: false
      })
    } else if (isLimitUpgradeRequired) {
      const userLicense = getUserLicense()
      const renewalDate = new Date(userLicense.renewalDate).toLocaleDateString('en-US', {year: 'numeric', month: 'short', day: 'numeric'})

      upgradeButton = 'Upgrade'
      upgradeMessage = <span>Uh oh! This feature is unavailable until your plan renews on <b>{renewalDate}</b>. Please upgrade your plan to use it now.</span>

      this.setState({
        tooltipActivated: true,
        upgradeButton,
        upgradeUrl,
        upgradeMessage,
        isLimitedExport: true
      })
    } else {
      this.props.childaction()
    }
  }

  getUpgradeStatus = async () => {
    const {type, pid, publishTarget, presentoon: {hashid} = {}} = this.props
    const id = pid || hashid

    const [{publishAllowed}] = [await getPresentoonById(id), await getPublishData(id)]

    if (type === 'publishtargets') {
      this.getUpgradeStatusOrigin(publishTarget)
    } else {
      this.getUpgradeStatusOrigin(publishTarget, !publishAllowed)
    }
  }

  pageClick = event => {
    let {target} = event

    if (target === this.tooltipRef) {
      return
    }

    while (target && target.className !== 'upgrade-tooltip-wrapper') {
      target = target.parentNode

      if (target === this.wrapperRef) {
        return
      }
    }

    this.setState({tooltipActivated: false})
  }

  hideTooltip = event => {
    event.stopPropagation()
    this.setState({tooltipActivated: false})
  }

  upgrade = event => {
    const {publishTarget: {name}, type} = this.props

    event.stopPropagation()

    if (this.state.isLimitedExport) {
      userTrackingService.trackEventByParams('Internal Promotions', 'click', 'upgrade_max_out_exports', 10018, {location: 'upgrade_max_out_exports'})
    } else {
      userTrackingService.trackEventByParams('Internal Promotions', 'click', 'upgrade_freemium', 10016, {location: 'upgrade_freemium'})
    }

    this.setState({tooltipActivated: false})

    // If payment popups doesnt exist, open it regularly
    if (window.paymentPopups) {
      event.preventDefault()
      window.paymentPopups.showPricing()
    }

    userTrackingService.trackEventByParams(`upgrade_to_${name}`, 'upgrade', type)
  }

  setWrapperRef = ref => (this.wrapperRef = ref)

  setTooltipRef = ref => (this.tooltipRef = ref)

  componentDidUpdate = (preProps, prevState) => {
    const {tooltipActivated} = this.state

    if (prevState.tooltipActivated !== tooltipActivated && tooltipActivated) {
      const {publishTarget: {name} = {}} = this.props
      const label = `freemium_${name}`

      userTrackingService.trackEventByParams('internal_promotions', 'popup', label, 10017, {location: label})
    }
  }

  render() {
    const {type, children} = this.props
    const {tooltipActivated, isLimitedExport, upgradeMessage, upgradeButton, upgradeUrl} = this.state
    const tooltipClass = classnames(type, {
      hide: !tooltipActivated,
      limited: isLimitedExport
    })

    return (
      <div ref={this.setWrapperRef} className="upgrade-tooltip-wrapper" onClick={this.getUpgradeStatus}>
        <div ref={this.setTooltipRef} className={tooltipClass}>
          {upgradeMessage}
          <a href={upgradeUrl} target="_blank" className="upgradebutton hvr-rectangle-out" onClick={this.upgrade}>
            {upgradeButton}
          </a>
        </div>
        {children}
      </div>
    )
  }
}
