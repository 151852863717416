import {EventBus} from 'powtoon-commons/events'
import {createLogger} from 'logging'

const logger = createLogger('studio', 'event-bus')

export const defaultEventBus: EventBus = new EventBus(logger)

export const initStudioEventBus = (): EventBus => {
  return defaultEventBus
}
