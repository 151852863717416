import React, {Component} from 'react'

import PublishLink from './PublishLink'

// Writes if the exports is up to date or not.
// adds a link for publishing if not.
export default class VersionStatus extends Component {

  state = {
    isOutdated: false
  }

  componentDidMount() {
    this.checkIfOutDated()
  }

  checkIfOutDated = () => {
    if (parseInt(this.props.export.presentoonVersion) < parseInt(this.props.presentoon.currentVersion)) {
      this.setState({isOutdated: true})
    }
  }

  getText = () => {
    const {export: {category}} = this.props

    if (category === 'download') {
      return 'Update download version'
    } else if (category){
      return 'Update export version'
    } else {
      return 'Update'
    }
  }

  render() {
    const {pid, export: publishTarget, presentoon} = this.props

    return (
      <span>
        {
          this.state.isOutdated && (
            <span className="out-dated">
            (Outdated version)
              <PublishLink
                {...{pid, export: publishTarget, publishTarget, presentoon, text: this.getText()}}
              />
            </span>
          )}
      </span>
    )
  }
}