import {get} from 'lodash'

export const waitForProp = (baseObject, path, delayMillis) => {
  return new Promise((resolve) => {
    function resolveIfFound() {
      const prop = get(baseObject, path)
      if (prop !== undefined) {
        resolve(prop)
        return true
      } else {
        return false
      }
    }

    if (!resolveIfFound()) {
      const interval = setInterval(() => {
        if (resolveIfFound()) {
          clearInterval(interval)
        }
      }, delayMillis)
    }
  })
}