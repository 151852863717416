// @ts-expect-error in import
import JSON_ from 'json_'
import {UserMediaAssetItem} from 'powtoon-services/user-media-library/types'
import {
  IPowtoonEvent,
  IPowtoonUpdateEvent,
  IPowtoonCreatedEvent,
  IPowtoonClassChangeEvent,
  IPowtoonSubmissionChangeEvent,
  IPowtoonThumbnailUpdatedEvent,
  IPowtoonNameUpdatedEvent,
  ICharacterEditCompletedEvent,
  ILicenseUpdatedEvent,
  ICharacterEditErrorEvent,
  PowtoonEventType,
  IPowtoonUpdatedEvent,
  IReceivedFolderCreatedEvent,
  IFolderCreatedEvent,
  IReceivedFolderRenamedEvent,
  IFolderRenamedEvent,
  IPublishCompletedEvent,
  ICompanyTemplateUpdatedEvent,
  IPowtoonContentUpdatedEvent,
  IBalanceUpdatedEvent,
  ISessionExpiredEvent,
  ISessionExpiredEventData
} from './types'
import {IPowtoonAccessUpdatedEvent} from 'powtoon-commons/events/types'

export interface IServerNotificationEvent extends IPowtoonEvent {
  message: string
}

export const createServerNotificationEvent = (message: string): IServerNotificationEvent => ({
  type: PowtoonEventType.ServerNotification,
  message
})

export interface ICompanyTemplateCreated extends IPowtoonEvent {
  id: string
}

export interface IPublishFailedEvent extends IPowtoonEvent {
  powtoonId: string
  target: string
  category: string
}

export const createPublishCompletedEvent = (eventData: any): IPublishCompletedEvent => ({
  type: PowtoonEventType.PublishCompleted,
  powtoonId: eventData.presentoon_hashid,
  target: eventData.target,
  category: eventData.category,
  downloadUrl: eventData.download_url,
  powtoonPageUrl: eventData.powtoon_page_url,
  externalUrl: eventData.external_url,
  captionsUrl: eventData.closed_captions_url
})

export const createPublishFailedEvent = (eventData: any): IPublishFailedEvent => ({
  type: PowtoonEventType.PublishFailed,
  powtoonId: eventData.presentoon_hashid,
  target: eventData.target,
  category: eventData.category
})

export const createPowtoonCreatedEvent = (eventData: any): IPowtoonCreatedEvent => ({
  type: PowtoonEventType.PowtoonCreated,
  powtoonId: eventData.powtoon_id,
  parentFolderId: eventData.parent_folder_id || 'root'
})

export const createPowtoonUpdatedEvent = (eventData: any): IPowtoonUpdatedEvent => ({
  type: PowtoonEventType.PowtoonUpdated,
  data: JSON_.parse(JSON.stringify(eventData))
})

export const createPowtoonDeletedEvent = (eventData: any): IPowtoonUpdateEvent => ({
  type: PowtoonEventType.PowtoonDeleted,
  powtoonId: eventData.powtoon_id
})

export const createClassChangedEvent = (eventData: any): IPowtoonClassChangeEvent => ({
  type: PowtoonEventType.ClassChanged,
  classId: eventData.class_id,
  action: eventData.action
})

export const createSubmissionChangedEvent = (eventData: any): IPowtoonSubmissionChangeEvent => ({
  type: PowtoonEventType.SubmissionChanged,
  powtoonId: eventData.powtoon_id,
  classId: eventData.class_id,
  action: eventData.action,
  submissionId: eventData.submission_id
})

export const createPowtoonThumbnailUpdatedEvent = (eventData: any): IPowtoonThumbnailUpdatedEvent => ({
  type: PowtoonEventType.PowtoonThumbnailUpdated,
  powtoonId: eventData.powtoon_id,
  thumbUrl: eventData.thumb_url
})

export const createPowtoonNameUpdatedEvent = (eventData: any): IPowtoonNameUpdatedEvent => ({
  type: PowtoonEventType.PowtoonNameUpdated,
  powtoonId: eventData.powtoon_id,
  name: eventData.name
})
export interface IPowtoonRecordingVideoEvent extends IPowtoonUpdateEvent {
  videoUrl: string
}
export const createPowtoonRecordingVideoEventUpdatedEvent = (eventData: any): IPowtoonRecordingVideoEvent => ({
  type: PowtoonEventType.PowtoonRecordingVideoUrlUpdated,
  powtoonId: eventData.hash_id,
  videoUrl: eventData.video_url
})

export interface IPowtoonLockUpdatedEvent extends IPowtoonUpdateEvent {
  isLocked: boolean
}

export interface IContentVideoUrlCreatedEvent extends IPowtoonUpdateEvent {
  videoUrl: string
}

export const createPowtoonLockUpdatedEvent = (eventData: any): IPowtoonLockUpdatedEvent => ({
  type: PowtoonEventType.PowtoonUpdated,
  powtoonId: eventData.powtoon_id,
  isLocked: eventData.is_locked
})

export const createContentVideoUrlCreatedEvent = (eventData: any): IContentVideoUrlCreatedEvent => ({
  type: PowtoonEventType.PowtoonContentVideoUrlCreated,
  powtoonId: eventData.powtoon_id,
  videoUrl: eventData.content.video_url
})

export const createLicenseUpdatedEvent = (): ILicenseUpdatedEvent => ({
  type: PowtoonEventType.LicenseUpdated
})

export const createBalanceUpdatedEvent = (): IBalanceUpdatedEvent => ({
  type: PowtoonEventType.BalanceUpdated
})

export const createCharacterEditCompletedEvent = (
  origCharacterId: string,
  newCharacterId: string,
  oriAssetId?: string,
  newAsset?: UserMediaAssetItem
): ICharacterEditCompletedEvent => ({
  type: PowtoonEventType.CharacterEditCompleted,
  origCharacterId,
  newCharacterId,
  oriAssetId,
  newAsset
})

export const createCharacterEditErrorEvent = (error: string): ICharacterEditErrorEvent => ({
  type: PowtoonEventType.CharacterEditError,
  error
})

export const createPPTXReadyEvent = (eventData: any): IPowtoonUpdateEvent => {
  return {
    type: PowtoonEventType.PPTXPowtoonReady,
    powtoonId: eventData.powtoon_id
  }
}

export const createPPTXFailedEvent = (): IPowtoonEvent => ({
  type: PowtoonEventType.PPTXFailed
})

export const folderEvent = (eventData: any) => {
  const {action} = eventData

  if (action === 'created') {
    return createFolderCreateEvent(eventData)
  } else if (action === 'renamed') {
    return createFolderRenamedEvent(eventData)
  } else {
    return createFolderDeletedEvent(eventData)
  }
}

export const createFolderCreateEvent = (eventData: IReceivedFolderCreatedEvent): IFolderCreatedEvent => ({
  type: PowtoonEventType.FolderCreated,
  folderId: eventData.folder_id,
  name: eventData.name,
  parentFolderId: eventData.parent_id
})

export const createFolderRenamedEvent = (eventData: IReceivedFolderRenamedEvent): IFolderRenamedEvent => ({
  type: PowtoonEventType.FolderRenamed,
  folderId: eventData.folder_id,
  name: eventData.name
})

export const createFolderDeletedEvent = (eventData: any) => ({
  type: PowtoonEventType.FolderDeleted,
  folderId: eventData.folder_id
})

export const folderItemsChangedEvent = (eventData: any) => ({
  type: PowtoonEventType.MoveToFolder,
  added: eventData.added,
  removed: eventData.removed
})

export const createConsumedExportEvent = (eventData: any) => ({
  type: PowtoonEventType.ConsumedExport,
  powtoonId: eventData.powtoon_id,
  limitedExportBalance: eventData.limited_exports_balance
})

export const userAssetUpdatedEvent = (eventData: any) => ({
  type: PowtoonEventType.UserAssetUpdated,
  characterId: eventData.user_character_id,
  prototypeAssetId: eventData.prototype_asset_id
})

export const createPowtoonAccessEvent = (
  eventData: {powtoon_id: string},
  accessRevoked?: boolean
): IPowtoonAccessUpdatedEvent => ({
  type: accessRevoked ? PowtoonEventType.PowtoonAccessRevoked : PowtoonEventType.PowtoonAccessGranted,
  powtoonId: eventData.powtoon_id
})

export const createPowtoonAccessUpdated = (eventData: {powtoon_id: string}): IPowtoonAccessUpdatedEvent => ({
  type: PowtoonEventType.PowtoonAccessUpdated,
  powtoonId: eventData.powtoon_id
})

export const createCompanyTemplateCreatedEvent = (eventData: {id: string}): ICompanyTemplateCreated => ({
  type: PowtoonEventType.CompanyTemplateCreated,
  id: eventData.id
})

export const createCompanyTemplateUpdatedEvent = (eventData: {
  id: string
  preview_link: string
}): ICompanyTemplateUpdatedEvent => ({
  type: PowtoonEventType.CompanyTemplateUpdated,
  id: eventData.id,
  previewLink: eventData.preview_link
})

export const createPowtoonContentUpdatedEvent = (eventData: {
  powtoon_id: string
  isBroadcastEvent?: boolean
}): IPowtoonContentUpdatedEvent => ({
  type: PowtoonEventType.PowtoonContentUpdated,
  data: {
    powtoonId: eventData.powtoon_id,
    isBroadcastEvent: eventData.isBroadcastEvent
  }
})

export const createSessionExpiredEvent = (data: ISessionExpiredEventData = {}): ISessionExpiredEvent => ({
  type: PowtoonEventType.SessionExpired,
  data
})
