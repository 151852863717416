import React, {Component} from 'react'
import {getExportsForTable, getPresentoonById} from './externalApiBridge'
import ExportAction from './ExportAction'
import './ManageExports.scss'

const Sentry = typeof window !== 'undefined' && window.Sentry

// A list of exports
// props - pid, id, exports
export default class ManageExports extends Component {
  state = {
    exports: [],
    presentoon: {}
  }

  componentDidMount() {
    this.loadDataFromServer()
  }

  loadDataFromServer = async () => {
    const {pid: presentoonId, id: powtoonHashId} = this.props

    if (presentoonId === undefined) {
      return Sentry.captureException(new Error('Invalid presentoon id'))
    }

    if (powtoonHashId === undefined) {
      return Sentry.captureException(new Error('Invalid powtoon hash id'))
    }

    try {
      this.setState({
        presentoon: await getPresentoonById(presentoonId),
        exports: await getExportsForTable(powtoonHashId)
      })
    } catch (e) {
      Sentry.captureException(e.responseText)
    }
  }

  render() {
    const {pid} = this.props
    const {exports, presentoon} = this.state

    return (
      <div className="export-list powtoon-rebirth">
        <div className="heading row">
          <div className="date col-md-3">Date</div>
          <div className="type col-md-3">Type</div>
          <div className="mode col-md-3">Mode</div>
          <div className="action col-md-3">Action</div>
        </div>
        {exports.map(exportNode => (
          <div className="export row" key={exportNode.name}>
            <div className="created col-md-3">{exportNode.created || '-- -- -- -- -- -- -- --'}</div>
            <div className="col-md-3">
              <div className={`icon pticon-${exportNode.name}icon`} />
            </div>
            <div className="mode col-md-3">{exportNode.mode}</div>
            <div className="action col-md-3">
              <ExportAction export={exportNode} pid={pid} presentoon={presentoon} />
            </div>
          </div>
        ))}
      </div>
    )
  }
}
