import {noop} from 'lodash'

const HASH_PREFIX = '#'
const VALID_HOST_URL = 'https://www.powtoon.com/'

export const addQueryInUrl = (
  query: {[key: string]: string},
  url: string = window.location.href,
  logger: {error: (err: any) => void} = {error: noop}
): string => {
  if (typeof query !== 'object') {return url}

  try {
    const myUrl = Object.entries(query).reduce((acc, [key, value]) => {
      acc.searchParams.set(key, value)
      return acc
    }, new URL(url))

    return myUrl.toString()
  } catch (err) {
    logger.error(err)
    return url
  }
}

export const addQueryInHashUrl = (
  query: {[key: string]: string},
  url: string = window.location.href,
  logger: {error: (err: any) => void} = {error: noop}
) => {
  if (typeof query !== 'object') {return url}

  try {
    const newUrl = new URL(url)
    const hash = newUrl.hash ? newUrl.hash.replace(HASH_PREFIX, VALID_HOST_URL) : VALID_HOST_URL
    newUrl.hash = addQueryInUrl(query, hash, logger).replace(VALID_HOST_URL, HASH_PREFIX)
    return newUrl.toString()
  } catch (err) {
    logger.error(err)
    return url
  }
}

export const getCurrentPageUrlParams = (search = window.location.search) => new URLSearchParams(search)

export const convertUrlParamsToCorrectType = (value: string): any => {
  try {
    return JSON.parse(value)
  }
  catch {
    return value
  }
}

export const getPartitionedSearchedParams = (queryKey: string, search?: string): [string[], URLSearchParams] => {
  const searchParams = getCurrentPageUrlParams(search)
  const matchedQueriesArray = searchParams.getAll(queryKey)
  searchParams.delete(queryKey)
  return [matchedQueriesArray, searchParams]
}

export const getSubdomain = (): string => {
  const subdomain = window.location.host.split('.powtoon.com')[0]
  return subdomain === 'powtoon.com' ? '' : subdomain
}

export const isRelativePath = (url: string): boolean => {
  // @ts-expect-error method is not defined in current TS definition, while presented in spec
  return !URL.canParse(url)
}