import {IPowtoonEvent, PowtoonEventType} from './types'


export type PowtoonEventHandler = (event: IPowtoonEvent) => void

export class EventBus {
  private subscribers: {
    [key in PowtoonEventType]: PowtoonEventHandler[]
  }
  private readonly logger: any

  constructor(logger: any = console) {
    this.subscribers = {} as any
    this.logger = logger

    this.on = this.on.bind(this)
    this.off = this.off.bind(this)
    this.publish = this.publish.bind(this)
  }

  public on(eventType: PowtoonEventType, handler: PowtoonEventHandler) {
    this.subscribers[eventType] = this.subscribers[eventType] || []
    this.subscribers[eventType].push(handler)
  }

  public off(eventType: PowtoonEventType, handlerToRemove: PowtoonEventHandler) {
    if (this.subscribers[eventType]) {
      this.subscribers[eventType] = this.subscribers[eventType].filter(handler => handler !== handlerToRemove)
    }
  }

  public publish(event: IPowtoonEvent) {
    const eventType = event.type
    const handlersList = this.subscribers[eventType] || []
    this.logger.info(`Event published to EventBus, ${handlersList.length} handlers available`, event)
    handlersList.forEach(handler => {
      try {
        handler(event)
      } catch (e) {
        this.logger.error(new Error('eventHandler exception.'), {exception: e, event})
      }
    })
  }
}
