/* istanbul ignore file */
import {remoteLogWithLevel, logWithLogzioMetrics} from './remoteLogWithLevel'
import events from '../services/events'
const startTime = Date.now()

import uuid from 'powtoon-commons/utils/uuid'
import {isNewRelicAvailable} from '../utils'

const defaultLoggerConfig = {
  logzioId: null,
  logzioMetricsId: null,
  logzioUrl: null,
  tabId: uuid(),
  appName: 'n.a',
  appVersion: 'n.a',
  moduleName: 'general',
  loggerName: 'general',
  type: 'frontendapps',
  logLocally: true,
  logRemotely: false,
  // Temporal default level, will be debug after STUD-13963
  logLevel: 'info'
}


const levelsArray = ['debug', 'info', 'log', 'warn', 'error']

const listOfLoggers = []

const getListOfAvailableLogLevels = minLogLevel =>
  levelsArray.slice(levelsArray.indexOf(minLogLevel || defaultLoggerConfig.logLevel))

events.on('log-level-changed', minLogLevel =>
  listOfLoggers.forEach(logger => {
    logger.availableLogLevels = getListOfAvailableLogLevels(minLogLevel)
  }))

export const createLogger = (identity, data, config) => {
  const logger = new Logger(identity, data, config)

  logger.availableLogLevels = getListOfAvailableLogLevels(config.logLevel)
  listOfLoggers.push(logger)

  return logger
}

class Logger {
  constructor(identity, data, {experimentsSetup,  ...config}) {
    this.identity = {
      moduleName: identity.moduleName || defaultLoggerConfig.moduleName,
      loggerName: identity.loggerName || defaultLoggerConfig.loggerName
    }
    this.data = data
    this.config = config
    this.localConsolePrefix = `[${this.identity.moduleName}|${this.identity.loggerName}]`
  }

  performanceLog = (title, record) => {
    if (isNewRelicAvailable()) {
      //search in network URL for debug bam.nr-data.net
      console.info(`newrelic ${title} `, ...record )
      try {
        window.newrelic.addPageAction(title, record)
      }
      catch (ex){
        console.error('newrelic', ex)
      }
    }

    if (this.config.logRemotely) {
      const config = this.getRemoteLoggerConfig()
      logWithLogzioMetrics('log', [title], {...config, ...record})
    }

    this.log(title, record)
    return null
  }

  getRemoteLoggerConfig = () => {
    // eslint-disable-next-line no-unused-vars
    const {logRemotely, logLocally, logLevel, ...cleanConfig} = {
      ...defaultLoggerConfig,
      ...this.identity,
      ...this.config,
      ...this.data
    }

    return {
      ...cleanConfig,
      uptime: Date.now() - startTime
    }
  }

  logWithLevel = (level, ...args) => {
    if (!args[0]) {
      return
    }

    if (!this.availableLogLevels.includes(level)) {
      return
    }

    if (this.config.logLocally) {
      const [message, ...restArgs] = [...args]
      console[level](`${message} ${this.localConsolePrefix}`, ...restArgs)
    }

    if (this.config.logRemotely) {
      const config = this.getRemoteLoggerConfig()
      let title
      if ( typeof args ==  'string' || args instanceof String){
        title = args
        remoteLogWithLevel(level, [title], {...config})
      }
      else {
        const [title, restArgs] = args
        remoteLogWithLevel(level, [title], {...config, ...restArgs})
      }
    }
  }

  error = (...args) => this.logWithLevel('error', ...args)
  warn = (...args) => this.logWithLevel('warn', ...args)
  log = (...args) => this.logWithLevel('log', ...args)
  info = (...args) => this.logWithLevel('info', ...args)
  debug = (...args) => this.logWithLevel('debug', ...args)
}
