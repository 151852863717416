import {delay, noop} from 'lodash'
import {createLogger} from 'logging'
import externalTutorialService from 'powtoon-commons/externalTutorialService'
import {createResolveWhenReady} from 'powtoon-commons/utils/promises'
import {ITEM_TYPES} from 'powtoon-document/consts'

let timeouts

const logger = createLogger('userTrackingService')

const resolveWhenTrackEventByParamsReady = createResolveWhenReady(() => !!window.trackEventByParams)

const trackEventByParams = ({category, action, label, value, extraData, reportingOptions, ga4EventName}) => {
  return resolveWhenTrackEventByParamsReady().then(
    () => window.trackEventByParams(category, action, label, value, extraData, reportingOptions, ga4EventName)
  )
}

const resolveWhenRudderStackEventTrackerReady = createResolveWhenReady(
  () => !!window.rudderanalytics,
  {
    timeout: 30000
  }
)

export const rudderStackTrackingService = {
  report(event) {
    const rsReport = event.rudderStackReportingOptions
    if (rsReport) {
      resolveWhenRudderStackEventTrackerReady()
        .then(() => window.rudderanalytics.track(rsReport.event, rsReport.properties))
        .catch(() => logger.log('rudderstack not ready for 30 seconds'))
    }
  }
}

const resolveWhenSegmentEventTrackerReady = createResolveWhenReady(
  () => !!window.analytics,
  {
    timeout: 30000
  }
)

export const segmentTrackingService = {
  report(event) {
    const segmentReport = event.segmentReportingOptions
    if (segmentReport) {
      resolveWhenSegmentEventTrackerReady()
        .then(() => window.analytics.track(segmentReport.event, segmentReport.properties))
        .catch(() => logger.log('SegmentReport not ready for 30 seconds'))
    }
  }
}

export const appcuesTrackingService = {
  report(event) {
    const appcuesReport = event.appcuesReportingOptions
    if (appcuesReport) {
      externalTutorialService.track(appcuesReport.event, appcuesReport.properties)
    }
  }
}

const resolveWhenHeapEventTrackerReady = createResolveWhenReady(
  () => !!window.heap,
  {
    timeout: 50000
  }
)

export const heapTrackingService = {
  report(event) {
    const heapReport = event.heapReportingOptions
    if (heapReport) {
      resolveWhenHeapEventTrackerReady()
        .then(() => window.heap.track(heapReport.event, heapReport.properties))
        .catch(() => logger.log('HeapReport not ready for 50 seconds'))
    }
  }
}

const trackGTMEvents = (event) => {
  if (process.env.NODE_ENV === 'production' && !window.dataLayer) {
    logger.error(new Error('window.dataLayer is missing in production.'))
  }
  const pushGtmEvent = window.dataLayer ? window.dataLayer.push : noop
  return pushGtmEvent(event)
}

export const dataLayerTrackingService = {
  report(event) {
    const dlReport = event.dataLayerReportingOptions
    if (dlReport) {
      trackGTMEvents({event: dlReport.event, ...dlReport.properties})
    }
  }
}

export default {
  report(eventObj) {
    trackEventByParams(eventObj)
  },

  logError: logger.error,

  trackEventByParams(category, action, label, value, ga4EventName) {
    trackEventByParams({category, action, label, value, ga4EventName})
  },
  setStoryBoardTimeSpentTracker() {
    timeouts = [0, 1, 2, 4, 15, 30, 90, 180, 360].map(
      min => delay(
        () => {this.trackEventByParams(`time_in_story_${min}_min`, 'duration', 'storyboard')},
        20000 * min
      )
    )
  },
  clearStoryBoardTimeSpentTracker() {
    timeouts.forEach(item => clearTimeout(item))
  },
  getSpriteType(sprite) {
    return sprite.assetType || (sprite.itemType === ITEM_TYPES.IMAGE ? 'images' : sprite.itemType)
  }
}
