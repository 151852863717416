import React from 'react'
import {EventBus} from './EventBus'

export interface EventBusContextValue {
  eventBus: EventBus
}

const defaultEventBus = new EventBus()
export const buildEventBusContextValue = (eventBus?: EventBus): EventBusContextValue => {
  return ({
    eventBus: eventBus || defaultEventBus
  })
}

export const EventBusContext = React.createContext<EventBusContextValue>({
  eventBus: defaultEventBus
})