import {noop} from 'lodash'
import {createResolveWhenReady} from 'powtoon-commons/utils/promises'

const MOCK_TUTORIAL_SERVICE = {
  show: noop,
  page: noop,
  identify: noop,
  track: noop
}

const getService = () => window.Appcues || MOCK_TUTORIAL_SERVICE
const resolveWhenAppcuesReady = createResolveWhenReady(() => !!window.Appcues)

const externalTutorialService = {
  showIntro: (appcuesTourId: string) => getService().show(appcuesTourId),
  reportPageChange: (page: string) => getService().page(),
  identify: (userId: string | number, data: object) => resolveWhenAppcuesReady()
    .then(() => getService().identify(userId, data)),
  track: (event: string, properties?: object) => resolveWhenAppcuesReady()
    .then(() => getService().track(event, properties))
}

export default externalTutorialService