import React, {Component} from 'react'
import PublishLink from './PublishLink'
import VersionStatus from './VersionStatus'

const targetsNames = {
  facebookupload: 'facebook'
}

// The action cell, chooses between cases of in progress, finished, or link to export
// props - export, pid
export default class ExportAction extends Component {
  render() {
    return (
      <span>
        {this.getAction()}
      </span>
    )
  }

  getAction = () => {
    const {export: {created, finished, url}} = this.props

    if (finished) {
      return url ? this.getFinishedAction() : this.getPublishAction()
    }
    return created ? this.getInprogressAction() : this.getPublishAction()
  }

  getButtonTitle = () => {
    const {export: {category}} = this.props

    switch (category) {
      case 'download':
        return 'Download'
      case 'upload':
        return 'Watch Powtoon'
      default:
        return ''
    }
  }

  getFinishedAction = () => {
    const {export: publishTarget, pid, presentoon} = this.props

    return (
      <div>
        <a href={publishTarget.url} target="_blank"> {this.getButtonTitle()} </a>
        {publishTarget.presentoonVersion && presentoon.currentVersion && <VersionStatus {...{export: publishTarget, pid, presentoon}} />}
      </div>
    )
  }

  getInprogressAction = () => 'File creation in progress'

  getLinkTitle = () => {
    const {export: {category, name, finished, url, error}} = this.props

    switch (category) {
      case 'download':
        if (finished && (!url || error)) {
          return 'Recreate downloadable file'
        }
        return 'Create downloadable file'
      case 'upload':
        return `Export to ${targetsNames[name] || name}`
      case 'facebookads':
        return 'Export to Facebook Ads manager'
      default:
        return ''
    }
  }

  getPublishAction = () => {
    const {export: publishTarget, pid, presentoon} = this.props

    return (<PublishLink {...{pid, export: publishTarget, presentoon, publishTarget, text: this.getLinkTitle()}}/>)
  }
}
