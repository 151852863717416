import {UserMediaAssetItem} from 'powtoon-services/user-media-library/types'

export enum PowtoonEventType {
  PowtoonRecordingVideoUrlUpdated = 'PowtoonRecordingVideoUrlUpdated',
  PowtoonUpdated = 'PowtoonUpdated',
  PowtoonContentVideoUrlCreated = 'PowtoonVideoUrlCreated',
  PowtoonAccessGranted = 'PowtoonAccessGranted',
  PowtoonAccessRevoked = 'PowtoonAccessRevoked',
  PowtoonAccessUpdated = 'PowtoonAccessUpdated',

  PPTXPowtoonReady = 'PPTXPowtoonReady',
  PPTXFailed = 'PPTXFailed',
  FolderCreated = 'FolderCreated',
  FolderRenamed = 'FolderRenamed',
  FolderDeleted = 'FolderDeleted',
  MoveToFolder = 'MoveToFolder',

  ConsumedExport = 'ConsumedExport',
  PowtoonCreated = 'PowtoonCreated',
  PowtoonDeleted = 'PowtoonDeleted',
  PowtoonThumbnailUpdated = 'PowtoonThumbnailUpdated',
  PowtoonNameUpdated = 'PowtoonNameUpdated',

  LicenseUpdated = 'LicenseUpdated',

  PublishCompleted = 'PublishCompleted',
  PublishFailed = 'PublishFailed',

  ServerNotification = 'ServerNotification',
  ServerError = 'ServerError',

  CharacterEditCompleted = 'CharacterEditCompleted',
  CharacterEditClose = 'CharacterEditClose',
  CharacterEditError = 'CharacterEditError',

  ClassChanged = 'ClassChanged',
  SubmissionChanged = 'SubmissionChanged',
  UserAssetUpdated = 'UserAssetUpdated',

  PlayerToggleFullScreen = 'PlayerToggleFullScreen',
  WorkspaceGDPRCompleted = 'WorkspaceGDPRCompleted',

  ApplicationModelEvent = 'ApplicationModelEvent',
  ApplicationUIEvent = 'ApplicationUIEvent',

  CompanyTemplateCreated = 'CompanyTemplateCreated',
  CompanyTemplateUpdated = 'CompanyTemplateUpdated',


  StudioPresenceMemberJoined = 'StudioPresenceMemberJoined',
  StudioPresenceMemberLeave = 'StudioPresenceMemberLeave',

  PowtoonContentUpdated = 'PowtoonContentUpdated',

  BalanceUpdated = 'BalanceUpdated',

  SessionExpired = 'SessionExpired'
}
export interface IPowtoonEvent {
  type: PowtoonEventType
}

export type PusherEventConverter = (pusherEventName: string, pusherEventData: any) => IPowtoonEvent | void

export interface IPowtoonClassChangeEvent extends IPowtoonEvent {
  classId: string,
  action: string,
}
export interface IPowtoonSubmissionChangeEvent extends IPowtoonEvent {
  powtoonId: string,
  submissionId: string,
  classId: string,
  action: string,
}

// TODO: need improve types for Powtoon events
export interface IPowtoonUpdateEvent extends IPowtoonEvent {
  powtoonId: string
  [key: string]: any
}

export interface IPowtoonCreatedEvent extends IPowtoonEvent {
  powtoonId: string,
  parentFolderId: string
}

export interface IServerNotificationEvent extends IPowtoonEvent {
  message: string
}

export interface IPowtoonThumbnailUpdatedEvent extends IPowtoonUpdateEvent {
  thumbUrl: string
}
export interface IPowtoonNameUpdatedEvent extends IPowtoonUpdateEvent {
  name: string
}

export interface ICharacterEditCompletedEvent extends IPowtoonEvent {
  origCharacterId: string,
  newCharacterId: string,
  oriAssetId?: string,
  newAsset?: UserMediaAssetItem
}

export interface ICharacterEditErrorEvent extends IPowtoonEvent {
  error: string
}

export interface IPublishCompletedEvent extends IPowtoonUpdateEvent {
  target: string
  category: string
  downloadUrl: string
  powtoonPageUrl: string
  externalUrl: string,
  captionsUrl?: string
}

export interface IPublishFailedEvent extends IPowtoonEvent {
  powtoonId: string
  target: string
  category: string
}

// tslint:disable-next-line:no-empty-interface
export interface ILicenseUpdatedEvent extends IPowtoonEvent {

}

export interface IBalanceUpdatedEvent extends IPowtoonEvent {

}

export interface IPowtoonUpdatedEvent extends IPowtoonEvent {
  data: any
}

export interface IReceivedFolderCreatedEvent extends IPowtoonEvent {
  folder_id: string,
  name: string,
  parent_id: number
}

export interface IFolderCreatedEvent extends IPowtoonEvent {
  folderId: string,
  name: string,
  parentFolderId: number
}

export interface IReceivedFolderRenamedEvent extends IPowtoonEvent {
  folder_id: string,
  name: string,
}

export interface IFolderRenamedEvent extends IPowtoonEvent {
  folderId: string,
  name: string,
}

export interface INotificationOnUserAssetUpdated extends IPowtoonEvent {
  userCharacterId: string,
  prototypeAssetId: string
}

export interface IPowtoonAccessUpdatedEvent extends IPowtoonEvent {
  powtoonId: string
}

// tslint:disable-next-line:no-empty-interface
export interface IWorkspaceGDPRCompleted extends IPowtoonEvent {}

export interface IApplicationModelEvent<T> extends IPowtoonEvent {
  type: PowtoonEventType.ApplicationModelEvent,
  data: T
}

export interface IApplicationUIEvent<T> extends IPowtoonEvent {
  type: PowtoonEventType.ApplicationUIEvent,
  data: T
}

export interface ICompanyTemplateUpdatedEvent extends IPowtoonEvent {
  type: PowtoonEventType.CompanyTemplateUpdated,
  id: string,
  previewLink: string
}

interface IBroadcastEvent {
  isBroadcastEvent?: boolean
}

export interface IPowtoonContentUpdatedEvent extends IPowtoonEvent {
  type: PowtoonEventType.PowtoonContentUpdated,
  data: {
    powtoonId: string,
  } & IBroadcastEvent
}

export interface ISessionExpiredEventData extends IBroadcastEvent {}

export interface ISessionExpiredEvent extends IPowtoonEvent {
  type: PowtoonEventType.SessionExpired,
  data?: ISessionExpiredEventData
}
