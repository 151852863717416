const callbacks = {}

const events = {

  on(e, ...callback) {
    if (callback.length && callbacks[e] && callbacks[e].length) {
      callbacks[e].push(...callback)
    }
    else if (callback.length) {
      callbacks[e] = []
      callbacks[e].push(...callback)
    }

    return this
  },

  trigger(e, ...args) {
    if (callbacks[e] && callbacks[e].length) {
      callbacks[e].forEach((x) => {
        x(...args)
      })
    }

    return this
  },

  off(e) {
    if (callbacks[e]) {
      delete callbacks[e]
    }

    return this
  }
}

export default events