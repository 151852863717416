export {SLIDE_WIDTH, SLIDE_HEIGHT} from 'powtoon-commons/models/consts'
import webvarsConfigurationService from 'common/services/webvarsConfigurationService'
export const CONFLICT = 409
export const FORBIDDEN = 403
export const NOT_FOUND = 404
export const UNAUTHORIZED = 401

export const SPACE_KEY = 32

export const GRADIENT_DEFAULT_PADDING = 15

export const STUDIO_STATES = {
  INIT: 'init',
  START: 'start',
  LOADING: 'loading',
  READY: 'ready',
  LOCKED: 'locked',
  ACCESS_DENIED: 'accessDenied',
  ACCESS_REVOKED: 'accessRevoked',
  USER_LOGGED_OUT: 'loggedOut',
  ERROR: 'error',
  NOT_FOUND: 'notFound'
} as const

export const LIBRARY_WIDTH = 380

export const MIN_SLIDE_DURATION = 1
export const MAX_SLIDE_DURATION = 20
export const MAX_ASSETS_PER_SECOND = 48
export const ADD_TRACKS_TIMELINE_STEP = 0.5
export const INITIAL_TIMELINE_TIME = 1


export const FILE_PICKER_API_KEY = webvarsConfigurationService.getWebVar('filePickerApiKey')
export const GET_PROFESSIONAL_URL = 'https://bunnystudio.com/voice/projects/add/#/?p=powtoon'
export const HIRE_MUSIC_MAKER_FIVER_URL = 'https://go.fiverr.com/visit/?bta=20984&brand=fiverrcpa&landingPage=https%253A%252F%252Fwww.fiverr.com%252Fcp%252Fpowtoon-music-marketplace'
export const HIRE_IMAGE_DESIGNER_FIVER_URL = 'https://www.fiverr.com/cp/powtoon-designers-marketplace?utm_source=20984&utm_medium=cx_affiliate&utm_campaign=&afp=&cxd_token=20984_32441786&show_join=true'
export const HIRE_MEDIA_DESIGNER_FIVER_URL = 'https://www.fiverr.com/cp/powtoon-experts-marketplace?utm_source=20984&utm_medium=cx_affiliate&utm_campaign=&afp=&cxd_token=20984_32237019&show_join=true'
export const WELLSAID_URL = 'https://wellsaidlabs.com/?via=powtoon'

// adding assets to editor constants
export const MAX_TRACKS_IN_SEQUENCE = 10
export const TRACK_PLACEMENT_OFFSET = 20

export const USER_ZOOM_STEP = 25
export const USER_ZOOM_STEP_PERCENTAGE = 0.25
export const USER_ZOOM_MAX_STEP = 2
export const USER_ZOOM_DEFAULT_STEP = 0
export const USER_ZOOM_MIN_STEP = -2

export const ENTER_KEY_CODE = 13
export const ESC_KEY_CODE = 27
export const UP_ARROW_KEY_CODE = 38
export const DOWN_ARROW_KEY_CODE = 40
export const TAB_KEY_CODE = 9
export const ENTER_KEY = 'Enter'
export const TAB_KEY = 'Tab'

export const CTRL_Z_KEY_CODE = 90
export const CTRL_Y_KEY_CODE = 89

export const APP_TOOLBAR_HEIGHT = 60
export const APP_TIMELINE_HEIGHT = 110

export const UPLOAD_STATUS = {
  INITIAL: 0,
  PROGRESS: 1,
  ERROR: 2,
  DONE: 3
}

export const MEDIA_DOCUMENT = {
  SOUND: 'SOUND',
  VIDEO: 'VIDEO',
  IMAGE: 'IMAGE'
}

// image manager constants
export const IMAGE_PREFERENCES = {
  ALLOWED_IMAGE_TYPES: [
    'image/png',
    'image/gif',
    'image/jpeg'
  ]
}
// sound manager constants
export const SOUND_PREFERENCES = {
  MASTER_VOLUME: 0.5,
  DEFAULT_TRACK_VOLUME: 0.5, // sound property is float
  MAX_FILE_SIZE: 12000000,
  MAX_BITRATE: 128,
  ALLOWED_SOUND_TYPES: [
    'audio/mp3',
    'audio/x-m4a'
  ]
}

export const PLAYMODE = {
  ONCE: 0,
  LOOP: 1
}

export const SOUND_TYPE = {
  SOUND: 0,
  VOICE: 1
}

export const UPDATING_SLIDELIS_MESSAGE_STATUS = {
  UNKNOWN: 'unknown',
  SHOW: 'show',
  HIDE: 'hide'
}

export const SHOW_PUBLISH_TARGET = {
  PUBLISH_FLOW_ONLY: 'publish-flow-only',
  STUDIO_AND_PUBLISH_FLOW:  'studio-and-publish-flow',
  STUDIO_ONLY: 'studio-only'
}

export const LINE_HEIGHT_DEFAULT_VALUE = 1.15
export const LETTER_SPACING_DEFAULT_VALUE = 0
export const LINE_HEIGHT_VALUE_RANGE = {min: 0.5, max: 2.5}
export const LETTER_SPACING_VALUE_RANGE = {min: -0.2, max: 1.2}
export const LETTER_SPACING_DEFAULT_ON_MULTIPLE =  0.5
export const LINE_HEIGHT_DEFAULT_ON_MULTIPLE =  1.5
