import {get} from 'lodash'
import webvarsConfigurationService from 'common/services/webvarsConfigurationService'
import {createLogger as createPowtoonLogger} from 'powtoon-commons/logger'
import cookie from 'cookie'
// @ts-ignore
import {getCorePlayerImports} from 'powtoon-html-player/src/CorePlayerImports'
const {getWebVar} = webvarsConfigurationService
const logzioId = getWebVar('logzioId')
const logzioMetricsId = getWebVar('logzioMetricsId')
const logzioUrl = getWebVar('logzioUrl')
const logLevel = getWebVar('min_log_level')
const appLevel = getWebVar('appLevel')
const isProdEnv = getWebVar('isProdEnv')
let {visitorid: visitorId} = cookie.parse(document.cookie)
let powtoonId = get(window.location.hash.match(/\/edit\/(\w*).?$/), '[1]')|| 'n.a'
let userId = getWebVar('userId')

const data = {
  logzioId,
  logzioMetricsId,
  logzioUrl,
  loginSessionId: getWebVar('loginSessionId'),
  appName: process.env.ENTRY_POINT_NAME,
  appVersion: process.env.BUILD_IDENTIFIER,
  appLevel: appLevel || 'n.a',
  userAgent: window.navigator.userAgent,
  client_ip: getWebVar('clientIP'),
  get powtoonId() {
    return powtoonId || 'n.a'
  },
  set powtoonId(value) {
    powtoonId = value
  },
  get userId() {
    return userId || 'n.a'
  },
  set userId(value) {
    userId = value
  },
  set visitorId(value) {
    visitorId = value
  },
  get visitorId() {
    return visitorId || 'n.a'
  },
  corePlayerVersion: null
}

getCorePlayerImports().then((CorePlayerImports: any) => {
  data.corePlayerVersion = CorePlayerImports.getVersion()
})


const logConfig = {
  logLocally: !isProdEnv,
  logRemotely: !!logzioId,
  logLevel: !logLevel && isProdEnv ? 'log' : logLevel,
  experimentsSetup: {
    enable_slide_list: webvarsConfigurationService.getWebVar('enableSlideList')
  }
}

export const setUserIdentity = (
  {userId: userID, powtoonId: powtoonID, visitorId: visitorID}: {userId: string, powtoonId: string, visitorId: string}
) => {
  data.userId = userID
  data.powtoonId = powtoonID
  data.visitorId = visitorID
}

export const createLogger = (moduleName: string, loggerName?: string) =>
  createPowtoonLogger({moduleName, loggerName}, data, logConfig)
