import {noop, isNil} from 'lodash'
import JSON_ from 'json_'

// eslint-disable-next-line max-len
const REG_EXP_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const isValidEmail= email => REG_EXP_EMAIL.test(email)
const _pipe = (f, g) => (...args) => g(f(...args))
export const pipe = (...fns) => fns.reduce(_pipe)

export const takeFirstDefinedForKeys = variables => Object.entries(variables).reduce((result, [key, values]) => ({
  ...result,
  [key]: values.find(value => !isNil(value))
}), {})

export function shallowEqual(objA, objB, levelToCheck = 0) {
  if (objA === objB) {
    return true
  }

  function compare(innerObgA, innerObgB, level) {
    if (typeof innerObgA !== 'object' || innerObgA === null ||
      typeof innerObgB !== 'object' || innerObgB === null) {
      return false
    }

    const keysA = Object.keys(innerObgA)
    const keysB = Object.keys(innerObgB)

    if (levelToCheck === level) {
      if (keysA.length !== keysB.length) {
        return false
      }

      for (let i = 0; i < keysA.length; i++) {
        if (!innerObgB.hasOwnProperty(keysA[i])
          || innerObgA[keysA[i]] !== innerObgB[keysA[i]]) {
          return false
        }
      }

      return true
    }

    for (let i = 0; i < keysA.length; i++) {
      if (!compare(innerObgA[keysA[i]], innerObgB[keysA[i]], level + 1)) {
        return false
      }
    }

    return true
  }

  return compare(objA, objB, 0)
}

export const isNewRelicAvailable = () => typeof window.newrelic !== 'undefined'

export const hasAnimationGraphs = track => !!track?.animationGraphs

export const isDynamicTrack = track => track.hasOwnProperty('dynamic')

export const catchErrorIfPromiseReceived = prom => {
  if (prom instanceof Promise) {
    prom.catch(noop)
  }
}

export const transformJSON2SnakeCase = jsonObj => JSON.parse(JSON_.stringify(jsonObj))
export const transformJSON2CamelCase = jsonObj => JSON_.parse(JSON.stringify(jsonObj))

export const getUrlVars = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const urlParamsObj = {}
  for (const searchParam of urlParams) {
    urlParamsObj[searchParam[0]] = decodeURIComponent(searchParam[1])
  }
  return urlParamsObj
}

export function getRectangleCenterPoint(rect) {
  return {
    x: (rect.x || rect.left || 0) + (rect.width / 2),
    y: (rect.y || rect.top || 0) + (rect.height / 2)
  }
}

export function calcItemLocationByCenterPoint(centerX, centerY, {width, height}) {
  return {
    y: centerY - (height / 2),
    x: centerX - (width / 2)
  }
}

export function alignItemByCenterPoint(destinationItem, existingItem) {

  const {x: centerX, y: centerY} = getRectangleCenterPoint(existingItem)

  return {
    ...destinationItem,
    ...calcItemLocationByCenterPoint(centerX, centerY, destinationItem)
  }
}

export function alignItemToBottomCenter(destinationItem, existingItem, paddingBottom = 2) {
  const {x: centerX} = getRectangleCenterPoint(existingItem)
  return {
    ...destinationItem,
    ...calcItemLocationByCenterPoint(centerX, undefined, destinationItem),
    y: existingItem.height - destinationItem.height - paddingBottom
  }
}

export function alignItemToTopCenter(destinationItem, existingItem, paddingTop = 0) {
  const {x: centerX} = getRectangleCenterPoint(existingItem)
  return {
    ...destinationItem,
    ...calcItemLocationByCenterPoint(centerX, undefined, destinationItem),
    y: paddingTop
  }
}

export const isLocalDevEnv = process.env.NODE_ENV !== 'production'


export function percentageSizeToPixelSize(percentageRectangle, originalSize, zoom = 1) {

  const {width = 0, height = 0} = originalSize || {}
  const {height: rectangleHeight, width: rectangleWidth} = percentageRectangle

  const xCoordinate = isNil(percentageRectangle['x']) ? 'left' : 'x'
  const yCoordinate = isNil(percentageRectangle['y']) ? 'top' : 'y'

  return {
    [xCoordinate]: width * percentageRectangle[xCoordinate] * zoom / 100,
    [yCoordinate]: height * percentageRectangle[yCoordinate] * zoom / 100,
    left: width * percentageRectangle[xCoordinate] * zoom / 100,
    top: height * percentageRectangle[yCoordinate] * zoom / 100,
    height: height * rectangleHeight * zoom / 100,
    width: width * rectangleWidth * zoom / 100
  }
}

export function pixelSizeToPercentageSize(rectangle, originalSize, zoom = 1) {

  const {width, height} = originalSize
  const {height: rectangleHeight, width: rectangleWidth} = rectangle

  const xCoordinate = isNil(rectangle['x']) ? 'left' : 'x'
  const yCoordinate = isNil(rectangle['y']) ? 'top' : 'y'

  return {
    [xCoordinate]: rectangle[xCoordinate] * 100 / (width * zoom),
    [yCoordinate]: rectangle[yCoordinate] * 100 / (height * zoom),
    left: rectangle[xCoordinate] * 100 / (width * zoom),
    top: rectangle[yCoordinate] * 100 / (height * zoom),
    width: rectangleWidth * 100 / (width * zoom),
    height: rectangleHeight * 100 / (height * zoom)
  }
}
