import {SHOW_PUBLISH_TARGET}  from 'common/consts'

export const showInStudio = (field) => field && (
  field === SHOW_PUBLISH_TARGET.STUDIO_ONLY ||
  field === SHOW_PUBLISH_TARGET.STUDIO_AND_PUBLISH_FLOW
)

export const  showInPublishFlow = (field) => field && (
  field === SHOW_PUBLISH_TARGET.PUBLISH_FLOW_ONLY ||
  field === SHOW_PUBLISH_TARGET.STUDIO_AND_PUBLISH_FLOW
)