import {isError, throttle} from 'lodash'
import {stringifyObjectProps} from 'powtoon-commons/utils/stringifyObjectProps'
import LogzioLogger from './LogzioLogger'
const Sentry = typeof window !== 'undefined' && window.Sentry

/* eslint-enable */
let logzioLogger
let logzioMetricsLogger

const hasMemoryMeasurementFeature = !!(window.performance && window.performance.memory)
const getMemoryUsageObj = hasMemoryMeasurementFeature ?
  throttle(() => ({
    // [jsHeapSizeLimit] is the upper limit to the amount of bytes allocated to the app.
    memoryJsHeapBytesLimit: window.performance.memory.jsHeapSizeLimit,
    // [totalJsHeapSize] is current size of the JS heap including free space not occupied by any JS objects.
    memoryJSHeapBytesAllocated: window.performance.memory.totalJSHeapSize,
    // [usedJsHeapSize] is the total amount of memory being used by JS objects including V8 internal objects.
    memoryJSHeapBytesInUse: window.performance.memory.usedJSHeapSize
  }), 1000) :
  () => ({})

const getLogzioLogger = ({logzioId, logzioUrl}) => new Promise(resolve => {
  if (logzioLogger) {
    resolve(logzioLogger)
  } else {
    logzioLogger = new LogzioLogger({token: logzioId, url: logzioUrl})
    resolve(logzioLogger)
  }
})

const getLogzioMetricsLogger = ({logzioMetricsId, logzioUrl}) => new Promise(resolve => {
  if (logzioMetricsLogger) {
    resolve(logzioMetricsLogger)
  } else {
    logzioMetricsLogger = new LogzioLogger({token: logzioMetricsId, url: logzioUrl})
    resolve(logzioMetricsLogger)
  }
})

export function remoteLogWithLevel(level, args, config){
  const memoryUsageObj = getMemoryUsageObj()

  logWithLogzio(level, args, stringifyObjectProps(config), memoryUsageObj)
  logWithSentry(level, args, config, memoryUsageObj)
}

export function logWithLogzioMetrics(level, [errorOrMessage, ...otherArgs], config, memoryUsageObj) {
  if (config.logzioMetricsId) {
    getLogzioMetricsLogger({logzioMetricsId: config.logzioMetricsId, logzioUrl: config.logzioUrl})
      .then(logzioForMetricsLogger => {
        // eslint-disable-next-line no-unused-vars
        const {logzioMetricsId, logzioId, logzioUrl, ...cleanConfig} = config
        logzioForMetricsLogger.log({
          level,
          message: isError(errorOrMessage) ? errorOrMessage.message : errorOrMessage,
          args: JSON.stringify(otherArgs).substring(0, 1500),
          visitorId: 'n.a',
          powtoonMetricId: 'operation_metrics',
          ...memoryUsageObj,
          ...cleanConfig
        })
      })
  }
}

function logWithLogzio(level, [errorOrMessage, ...otherArgs], config, memoryUsageObj){
  getLogzioLogger({logzioId: config.logzioId, logzioUrl: config.logzioUrl})
    .then(logzioLogger => {
      // eslint-disable-next-line no-unused-vars
      const {logzioId, logzioMetricsId, logzioUrl, ...cleanConfig} = config
      logzioLogger.log({
        level,
        message: isError(errorOrMessage) ? errorOrMessage.message : errorOrMessage,
        args: JSON.stringify(otherArgs).substring(0, 1500),
        ...memoryUsageObj,
        visitorId: 'n.a',
        ...cleanConfig
      })
    })
}

const badLoggingErrorMessage = new Error(
  'Bad error logging arguments. Logger.error must receive and error as it`s first argument.'
)

function logWithSentry(level, [errorOrMessage, ...otherArgs], config, memoryUsageObj){
  if (!Sentry){
    console.error('No Sentry Found.')
    return
  }

  const logger = config.appName
  // eslint-disable-next-line no-unused-vars
  const {logzioId, logzioMetricsId, logzioUrl, ...cleanConfig} = config

  const extra = {
    args: otherArgs,
    visitorId: 'n.a',
    ...memoryUsageObj,
    ...cleanConfig
  }

  if (level !== 'error') {
    Sentry.addBreadcrumb({
      message: errorOrMessage,
      data: extra,
      category: logger,
      level
    })
    return
  }

  if (isError(errorOrMessage)) {
    Sentry.withScope(scope => {
      scope.setExtra('extra', extra)
      scope.setExtra('logger', logger)
      Sentry.captureException(errorOrMessage)
    })
    return
  }

  Sentry.withScope(scope => {
    scope.setExtra('extra', {
      ...extra,
      args: [errorOrMessage, ...otherArgs]
    })
    scope.setExtra('logger', logger)
    Sentry.captureException(badLoggingErrorMessage)
  })
}
