
interface LogzioLoggerConfig {
  token: string
  url?: string
}

const DEFAULT_LOGZIO_URL = 'https://listener.logz.io:8091'

export default class LogzioLogger {

  #token: string
  #url: string

  constructor({token, url}: LogzioLoggerConfig) {

    this.#token = token
    this.#url = url || DEFAULT_LOGZIO_URL
  }

  async log(data: string | object) {

    try {

      const logObj = typeof data === 'object' ? data : {message: data}
      const urlToReport = new URL(this.#url)

      urlToReport.searchParams.append('token', this.#token)

      await fetch(urlToReport.toString(), {
        body: JSON.stringify(logObj),
        credentials: 'include',
        method: 'POST',
        mode: 'no-cors'
      })
    }
    catch (e) {
      // tslint:disable-next-line:no-console
      console.log(`Failed to send log because of exception:\n${  e}`)
    }
  }
}
