import {getPowtoonPublishData} from './externalApiBridge'

let targets

export const getIsLicenseUpgradeRequired = target =>
  targets.currentPermissions.allowedPublishTargets.indexOf(target.name) === -1 &&
  (!targets.userLicense.isLimited || targets.exportCreditPermissions.allowedPublishTargets.indexOf(target.name) === -1)

export const getIsLimitUpgradeRequired = target =>
  targets.currentPermissions.allowedPublishTargets.indexOf(target.name) === -1 &&
  targets.exportCreditPermissions.allowedPublishTargets.indexOf(target.name) !== -1 &&
  targets.userLicense.isLimited &&
  targets.userLicense.exportCreditsBalance === 0

export const getIsUpgradeRequired = target => getIsLicenseUpgradeRequired(target) || getIsLimitUpgradeRequired(target)

export const getUserLicense = () => targets.userLicense

export const getPublishData = async id => {
  targets = await getPowtoonPublishData(id)
  return targets
}
