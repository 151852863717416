import 'powtoon-commons/types'
import {
  getCurrentPageUrlParams,
  convertUrlParamsToCorrectType
} from './urls'

const WEBVARS_URL_REX = /^webvars-/

export const getInitialWebVars = (useOriginalValues?: (webvars: any) => boolean) => {

  if (typeof window !== 'object') {
    return {} as any
  }

  const result = window.webvars || {}

  if (useOriginalValues?.(result)) {
    return result
  }

  return Array.from(getCurrentPageUrlParams().entries()).reduce((res, [key, value]) => {
    const newKey = key?.replace(WEBVARS_URL_REX, '') || key
    return (newKey === key) ? res : {...res, [newKey]: convertUrlParamsToCorrectType(value)}
  }, result)
}
