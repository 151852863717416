import {keyValueStringMaker} from './strings'


const FETCH_FAILED = 'Fetch failed'

const takeErrorBase = status => ({
  5: `${FETCH_FAILED} with server side error`,
  4: `${FETCH_FAILED} with client side error`
})[`${status || ''}`[0]] || FETCH_FAILED

const errorMessageMap = ['status', 'statusText', 'type', 'url', 'message']

const errorMessageSeparators = {pre: ', ', mid: ': '}

export const errorMessageMaker = (err, lastFailedResponse) => {

  if (typeof err === 'string') {
    return err ? `${FETCH_FAILED}: ${err}` : FETCH_FAILED
  }

  if (typeof err !== 'object') {
    return FETCH_FAILED
  }

  return errorMessageMap.reduce((acc, field, i) => {
    const separators = i ? errorMessageSeparators : {...errorMessageSeparators, pre: ': '}
    return `${acc}${keyValueStringMaker({
      key: field,
      value: err[field] || lastFailedResponse[field],
      separators
    })}`
  }, takeErrorBase(err.status))

}

export const errorMaker = (err, lastFailedResponse = null) => {
  
  const errorMessage = errorMessageMaker(err, lastFailedResponse)

  return {
    errorObj: err instanceof Error ? err : new ResponseError(errorMessage, lastFailedResponse.responseText),
    errorMessage
  }
}

export class ResponseError extends Error {
  constructor(errorMessage, responseBody = null) {
    super(errorMessage)
    this.responseBody = responseBody
  }
}

