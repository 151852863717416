import {isObject, isArray} from 'lodash'

export const stringifyObjectProps = (obj: any) => {
  const localObj = obj

  Object.keys(localObj).forEach(key => {
    const value = localObj[key]
    if (isObject(value) || isArray(value)) {
      localObj[key] = JSON.stringify(value)
    }
  })

  return localObj
}
