import React, {Component} from 'react'
import userTrackingService from 'common/services/userTrackingService'

import UpgradeTooltipWrapper from './UpgradeTooltipWrapper'

export default class PublishLink extends Component {
  publishPowtoon = () => {
    const {pid, export: {name, category, mode}} = this.props

    window.publishPowtoon(pid, name, category, mode, 'manage_exports_tab')
    userTrackingService.trackEventByParams(name, 'click', 'my_powtoons_manage_exports_tab')
  }

  render() {
    const {text, pid, publishTarget, export: exports, presentoon = {}} = this.props

    return (
      <UpgradeTooltipWrapper
        type="manageexports"
        className="UpgradeTooltip"
        {...{pid, export: exports, childaction: this.publishPowtoon, publishTarget, presentoon}}
      >
        <a href="javascript:void(0)">{text}</a>
      </UpgradeTooltipWrapper>
    )
  }
}
