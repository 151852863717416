import {waitForProp} from 'powtoon-commons/utils/waitForProp'

export const getCorePlayerImports = async () => {
  const corePlayer = await waitForProp(window, 'CorePlayer', 100)
  const {CorePlayerConnection, getVersion, MovieclipElement, calculateEasing} = corePlayer
  return {
    CorePlayerConnectionCtor: CorePlayerConnection,
    getVersion,
    MovieclipElement,
    calculateEasing
  }
}

export const getCorePlayerConnection = async () => {
  return await waitForProp(window, 'CorePlayer.corePlayerConnection', 100)
}

export const getCorePlayerReadyPromise = async () => {
  return await waitForProp(window, 'CorePlayer.corePlayerReadyPromise', 100)
}

export const getSlideThumbnailFunction = async () => {
  // We wait for the 'READY' event from the player, after the doc and all assets are loaded.
  return getCorePlayerReadyPromise().then(cpc => cpc.getSlideThumb)
}

export const getTextToImageFunction = async () => {
  // We wait for the CPC object to be instantiated.
  return getCorePlayerConnection().then(cpc => cpc.textToImage)
}

export const getExportFrameFunction = async () => {
  // We wait for the CPC object to be instantiated.
  return getCorePlayerConnection().then(cpc => cpc.exportFrame)
}

export const getCapturedGif = async () => {
  // We wait for the CPC object to be instantiated.
  return getCorePlayerConnection().then(cpc => cpc.captureGif)
}

export const setPlayerVolume = async newVolume => {
  // We wait for the CPC object to be instantiated.
  return getCorePlayerConnection().then(cpc => {
    if (typeof newVolume === 'number' && newVolume >= 0 && newVolume <= 1) {
      cpc.volume = newVolume
    }
  })
}
