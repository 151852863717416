import {noop} from 'lodash'

export const NOT_LATEST = 'Not Latest'

export const onlyResolveLatest = (wrappedFunc: (...args: any[]) => any) => {
  let prevRejectFunc = noop
  const generatedFunc = (...args: any[]) => new Promise((resolve, reject) => {
    prevRejectFunc(NOT_LATEST)
    prevRejectFunc = reject
    wrappedFunc(...args).then(resolve)
  })
  generatedFunc.cancelLastRequest = (e: Error) => prevRejectFunc(e)
  return generatedFunc
}

type CreateResolveWhenReadyOptions = {
  interval?: number
  timeout?: number
}

export const createResolveWhenReady = (checkIsReady: () => boolean, options: CreateResolveWhenReadyOptions = {}) => {
  const {interval = 1000, timeout} = options
  let intervalId: number | undefined
  let timeoutId: number | undefined
  let isActive = true
  let resolveFn: (val?: any) => void
  let rejectFn: (err?: any) => void

  const promise = new Promise<void>((resolve, reject) => {
    resolveFn = resolve
    rejectFn = reject
  })

  const checkTimeout = () => {
    if (timeout && !timeoutId) {
      timeoutId = setTimeout(() => {
        isActive = false
        clearInterval(intervalId)
        rejectFn?.(new Error('Timeout expired'))
      }, timeout)
    }
  }

  const runInterval = () => {
    checkTimeout()

    if (intervalId || !isActive) {
      return
    }

    intervalId = setInterval(() => {
      if (checkIsReady() && isActive) {
        isActive = false
        clearInterval(intervalId)
        resolveFn?.()
      }
    }, interval)
  }

  return (): Promise<void> => {
    if (isActive && checkIsReady()) {
      resolveFn?.()
      isActive = false
    }
    runInterval()
    return promise
  }
}
