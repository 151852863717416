import uuid from 'powtoon-commons/utils/uuid'
import {InteractionLock, Manifest, Powtoon, Slide, BackgroundColor} from 'powtoon-versioning/src/currentSchema'
import {PowtoonMetadata, ShortManifest, StudioSettingsMap} from '../types'

export const USER_ASSET_ID_PREFIXES = {
  RECORDING: 'recording'
}
export const MEDIA_SOURCES = {
  EXTERNAL_MEDIA_PROVIDER: 'externalMediaProvider',
  ENTREPRISE_GROUP_MEDIA: 'enterpriseGroupMedia'
}

export const STORYBLOCKS_SOUND_EFFECT =  'storyblockssounds'

export const ASSET_TYPES = {
  TRANSITION: 'transition',
  BACKGROUND: 'background',
  IMAGE_HOLDER: 'imageHolder',
  AVATAR: 'avatar',
  IMAGE: 'image',
  CHARACTER: 'character',
  OBJECT: 'object',
  COMPOSITE_ASSET: 'compositeAsset',
  SCENE: 'scene',
  TEMPLATE: 'template',
  CHART: 'chart',
  TEXT: 'text',
  MUSIC: 'music'
}

export const ITEM_TYPES = {
  IMAGE: 'image',
  VECTOR_GRAPHICS: 'vectorGraphics',
  IMAGE_HOLDER: 'imageHolder',
  MOVIE_CLIP: 'movieClip',
  COMPOSITE_ASSET: 'compositeAsset',
  SCENE: 'scene',
  TEMPLATE: 'template',
  CHART: 'chart',
  TEXT: 'text',
  FONT: 'font',
  SOUND: 'sound',
  VIDEO: 'video',
  ACTOR: 'actor',
  RECORDING: 'recording',
  SPINE: 'spine',
  COLOR: 'color',
  FYBER: 'fyber',
  CAMERA: 'camera',
  MUSIC: 'music'
}

export const FILE_FORMATS = {
  GIF: 'gif'
}

export const AUDIO_TYPES = {
  SOUNDTRACK: 'soundtrack',
  VOICEOVER: 'voiceover'
}

export const AUDIO_TRACK_INDEX = {
  SOUNDTRACK: 0,
  VOICEOVER: 1
}

export const ENTER_EXIT_EFFECT_IDS = {
  NO_EFFECT: 'None'
}

export const ENTER_EFFECT_IDS = {
  NO_EFFECT: ENTER_EXIT_EFFECT_IDS.NO_EFFECT,
  SLIDE_FROM_LEFT: 'EnterFromLeft',
  SLIDE_FROM_RIGHT: 'EnterFromRight',
  SLIDE_FROM_BOTTOM: 'EnterFromBottom',
  SLIDE_FROM_TOP: 'EnterFromTop',
  REVEAL_FROM_LEFT: 'RevealFromLeft',
  REVEAL_FROM_RIGHT: 'RevealFromRight',
  REVEAL_FROM_BOTTOM: 'RevealFromBottom',
  REVEAL_FROM_TOP: 'RevealFromTop',
  POP_IN: 'PopIn',
  FADE_IN: 'FadeIn',
  ZIGZAG_IN: 'ZigzagIn',
  MONTAGE_IN: 'MontageIn',
  SMEAR_IN: 'SmearIn'
}

export const WRITING_HAND_CONTENT_IDS = {
  NO_HAND: 'no_hand',
  FEMALE_WHITE_01: 'writing.hand.male.white.marker',
  MALE_BLACK_01: 'writing.hand.male.black.pencil',
  MALE_BLACK_02: 'writing.hand.male.black.marker',
  MALE_HISPANIC_01: 'writing.hand.male.hispanic.suit'
}
export const DRAWING_HAND_CONTENT_IDS = {
  NO_HAND: 'no_hand',
  FEMALE_WHITE_01: 'drawing.hand.male.white.marker',
  MALE_BLACK_01: 'drawing.hand.male.black.pencil',
  MALE_BLACK_02: 'drawing.hand.male.black.marker',
  MALE_HISPANIC_01: 'drawing.hand.male.hispanic.suit'
}

export const EXIT_EFFECT_IDS = {
  NO_EFFECT: ENTER_EXIT_EFFECT_IDS.NO_EFFECT,
  SLIDE_TO_RIGHT: 'ExitToRight',
  SLIDE_TO_LEFT: 'ExitToLeft',
  SLIDE_TO_TOP: 'ExitToTop',
  SLIDE_TO_BOTTOM: 'ExitToBottom',
  REVEAL_TO_LEFT: 'RevealToLeft',
  REVEAL_TO_RIGHT: 'RevealToRight',
  REVEAL_TO_BOTTOM: 'RevealToBottom',
  REVEAL_TO_TOP: 'RevealToTop',
  POP_OUT: 'PopOut',
  FADE_OUT: 'FadeOut'
}

export const ANIMATION_LOOP_BEHAVIOR = {
  NO_ANIMATION: 'none',
  ANIMATION: 'counted',
  NONSTOP_ANIMATION: 'endless'
}

export const DEFAULT_ANIMATION_LOOPS = 5

export const DEFAULT_SLIDE_DURATION = 10

export const EMPTY_MANIFEST: Manifest = {assets: {}, images: {}, fonts: {}, sounds: {}, videos: {}}
export const EMPTY_SHORT_MANIFEST: ShortManifest = {
  assets: [],
  fonts: [],
  images: [],
  sounds: [],
  videos: []
}

export const DEFAULT_SPRITE_WIDTH_AND_HEIGHT = 150

export const DEFAULT_BACKGROUND_COLOR = '#ffffff'
export const EMPTY_BACKGROUND: BackgroundColor = {
  color: DEFAULT_BACKGROUND_COLOR,
  itemType: 'color',
  opacity: 1
}

export const EMPTY_SLIDE: Slide = {
  id: uuid(),
  duration: DEFAULT_SLIDE_DURATION,
  background: {...EMPTY_BACKGROUND}, tracks: [],
  audioTracks: []
}

export const EMPTY_POWTOON: Powtoon = {
  exportAspectRatio: {method: 'none'},
  baseResolution: {width: 1024, height: 576},
  manifest: EMPTY_MANIFEST,
  slides: [EMPTY_SLIDE],
  audioTracks: [null, null],
  transitions: {}
}

export const DEFAULT_SELECTED_VISUAL_STYLE = 'professional'

export const DEFAULT_LIBRARY_STATE = {
  panelId: 'scenes',
  folderId: null
}

export const DEFAULT_STUDIO_SETTINGS: StudioSettingsMap = {
  default: {
    studioMode: 'create',
    lastPlayheadPosition: [],
    libraryState: DEFAULT_LIBRARY_STATE
  }
}

export const DEFAULT_METADATA: PowtoonMetadata = {
  dynamicItems: {},
  studioSettings: DEFAULT_STUDIO_SETTINGS,
  storedSlidelistThumbs: {},
  playerData: {
    data: {},
    updates: {}
  },
  conversionBannerSeen: false,
  structure: {contentSlides: [], dynamicSlides: {}},
  dynamicForm: {sections: []}
}

export const EMPTY_DOCUMENT = {
  powtoon: EMPTY_POWTOON,
  metadata: DEFAULT_METADATA
}

export const BLANK_THUMBNAIL =
  '<svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="160" height="90"><path fill="#fff" d="M160,0H0v90h160z"/></svg>'


export const SCALE_METHOD = {
  STANDARD: 'standard',
  NINE_SLICE: 'nineSlice'
}

export const INTERACTION_LOCK = {
  NONE: 'none' as InteractionLock,
  REGULAR: 'regular' as InteractionLock,
  ADMIN: 'admin' as InteractionLock
}

export enum AudioDataProvider {
  TextToSpeech = 'tts'
}

export const DEFAULT_SCRIPT_WRITER_SECTIONS = [
  {
    name: 'narration_text',
    text: ''
  },
  {
    name: 'on_screen_text',
    text: ''
  },
  {
    name: 'slide_description',
    text: ''
  }
]

export enum ContentSourceTypes {
  Producer = 'scenes-ai',
  TextToSpeechAI = 'tts-ai',
  ScriptWriter = 'script-ai',
  Pptx = 'pptx',
  Psd = 'psd',
  Template = 'template',
  Scratch = 'scratch',
  Flash = 'flash',
  Recording = 'recording',
  Scenes = 'scenes',
  Onboarding = 'onboarding_tutorial'
}